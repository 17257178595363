import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";


export default function BasicTable({ id, headers, rows }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={`${id}-${header}`} sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={`${id}-${row.name}-${row.date}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}        >
              <TableCell scope="row">
                <div>
                  {
                    row.link ?
                      <Link to={row.link}>
                        {row.name}
                      </Link>
                      : row.name
                  }
                </div>
              </TableCell>
              <TableCell>
                <div>
                  {
                    row.link ?
                      <Link to={row.link}>
                        {row.date}
                      </Link>
                      : row.date
                  }
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}