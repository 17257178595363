import * as React from 'react';
import { AccessTime } from '@mui/icons-material';


export default function Membership() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Membership</h1>
                <h2>Revised Membership Prices Effective September 1, 2023</h2>
                <h3 className='author'>Chris Brennan</h3>
                <p className='author-role'>Club Executive Committee | Treasurer</p>
                <p className='date'><span className='clock'><AccessTime fontSize="small" /></span>Tuesday 25th July 2023</p>
            </section>
            <section className='article-content'>
                <p>We are writing to inform you of an important change regarding our membership prices, which will take effect
                    from September 1, 2023. After careful consideration and evaluation of various factors, including rising
                    operational costs and the need for facility upgrades, we have found it necessary to revise our membership fees.
                    Starting September 1, 2023, the revised membership prices for the year will be as follows:</p>
                <ul className='bullet-list'>
                    <li>Adult: £150.00</li>
                    <li>Family (comprised of Parent/Guardian with Children under 16): £175.00</li>
                    <li>U18 (Under 18): £50.00</li>
                </ul>
                <p>We understand that any increase in membership fees may lead to questions about the value it provides.
                    However, we want to emphasize that the revised prices still represent great value for the numerous benefits and
                    opportunities that our club offers. Nevertheless, it's important to note that the membership fee covers only one
                    night of table tennis at Ards Table Tennis Club. If members wish to participate in additional nights beyond their
                    membership scope, an extra cost may be incurred to cover venue expenses and related costs.</p>
                <p>With that in mind, let's take a closer look at the per hour cost for each member category based on the prices
                    above, assuming you play 40 times a year for 2 hours at a time:</p>
                <ul className='bullet-list'>
                    <li>Adult: £1.88 per hour</li>
                    <li>Family: £2.19 per hour. However, when considering a family of two, such as a parent and child, the cost
                        per person is £1.10 per hour</li>
                    <li>U18 (Under 18): £0.63 per hour</li>
                </ul>
                <p>When considering the per hour cost, it becomes evident that the value received for the membership fee is
                    substantial. We sincerely appreciate your understanding and continued support as we strive to provide the best
                    table tennis experience for all members.</p>
                <p>In addition to the revised membership fees, Ards Table Tennis Club will now offer different models for paying
                    your membership, as we wanted to provide flexible options that cater to individual preferences and
                    circumstances. Starting from September 1, 2023, you will have the following choices for paying your
                    membership fees:</p>
                <ol style={{ "listStyleType": "decimal" }}>
                    <li>One-off Yearly Payment: This option allows you to pay the full annual membership fee in a single
                        payment. By choosing this method, you will receive the best value, although please note that
                        membership fees are non-refundable.</li>
                    <li>Monthly Standing Order: We are introducing a convenient monthly payment option. For £15 per month,
                        you can set up a standing order to cover your membership fees. This provides a more manageable
                        approach to budgeting, spreading the cost of your membership over the course of the year. With this
                        method, you have the freedom to cancel at any time, although the overall cost over the year may be
                        slightly higher.</li>
                    <li>Pay-as-You-Go: For those who prefer a flexible approach, we are introducing a pay-as-you-go option.
                        Under this model, you must pay £6 at the beginning of each session directly to the club treasurer, tuck
                        shop, or another committee member before participating in any table tennis activities. By opting for this
                        choice, you have the flexibility to participate in table tennis on a casual basis without committing to an
                        annual or monthly membership. Please note that this option offers the lowest value in terms of overall
                        cost.</li>
                </ol>
                <p>We hope that these alternative payment models will better accommodate your preferences and provide you
                    with more options to enjoy your membership at the club. If you have any further questions or concerns regarding
                    the value of your membership, please do not hesitate to reach out to us. In light of this and to discuss other
                    important club matters and upcoming plans, we will be holding our Annual General Meeting (AGM) on
                    Wednesday, August 30, 2023, at 7p.m. in the Queen's Hall. We hope to see all members in attendance at this
                    event.</p>
                <p>Thank you.</p>
                <p>David Hamilton (Chairman)</p>
                <p>Billy Mateer (Secretary)</p>
                <p>Chris Brennan (Treasurer)</p>
            </section >
        </div>
    )
}