import * as React from 'react';


export default function About() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>About Us</h1>
            </section>
            <section className='article-content'>
                <div className='Left-body brand-title-blue brand-content'>
                    <h1>What is ATTC?</h1>
                    <p>Ards Table Tennis Club (ATTC) is situated in the Centre of Newtownards and welcomes players of all ages and levels. We practice on Monday and Wednesday evenings from 19:00 - 21.00 at the Queens Hall, West St, Newtownards. Coaching sessions are ran on an ad hoc basis by certified Level 1, 2 and 3 coaches.</p>
                    <p>Contact <b>Billy Mateer</b> for more information on:</p>
                    <p><b>Tel:</b> 07484252671</p>
                    <p><b>Email:</b> <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></p>
                </div>
                <div className='Right-body brand-title-blue brand-content'>
                    <h1>Beginner</h1>
                    <p>Just starting... No problem! We offer beginner sessions for both adults and juniors</p>
                    <h1>Intermediate or Advanced</h1>
                    <p>Played before or getting ready for your first competitive league match? Why not try our intermediate or advanced coaching sessions</p>
                </div>
            </section>
        </div>
    )
}