import * as React from 'react';
import BasicTable from '../../components/BasicTable';

function createData(name, link, date) {
    return { name, link, date };
}

const rows = [
    createData('Ards & District Spring League 2024: The Jack Cash Cup', '/competitions/ards-and-district-spring-league-24', 'April-May 2024'),
    createData('Ards Masters Over 60s', '/competitions/des-flanagan-series-sep-23/ards-masters-over-60s', 'September, 2 2023 (PM)'),
    createData('Ards Open', '/competitions/des-flanagan-series-sep-23/ards-open', 'September, 2 2023 (PM)'),
    createData('Ards Masters over 40s', '/competitions/des-flanagan-series-sep-23/ards-masters-over-40s', 'September, 2 2023 (AM)'),
    createData('Ards & District Spring League 2023: The Jack Cash Cup', '/competitions/ards-and-district-spring-league-23', 'March-May 2023'),
];

export default function Competitions() {

    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Competitions</h1>
            </section>
            <section className='article-content'>
                <BasicTable id='competitons-page' headers={['Event Name', 'Date']} rows={rows} />
            </section>
        </div>
    )
}